@font-face {
  font-family: 'inter black';
  src: local('inter_black'), url(./inter/inter_black.ttf) format('truetype');
}

@font-face {
  font-family: 'inter light';
  src: local('inter_light'), url(./inter/inter_light.ttf) format('truetype');
}

@font-face {
  font-family: 'inter bold';
  src: local('inter_bold'), url(./inter/inter_bold.ttf) format('truetype');
}

@font-face {
  font-family: 'inter semibold';
  src: local('inter_semibold slnt=0'),
    url('./inter/inter_semibold slnt=0.ttf') format('truetype');
}

@font-face {
  font-family: 'inter regular';
  src: local('inter_regular'),
    url(./inter/inter_regular\ slnt=0.ttf) format('truetype');
}
