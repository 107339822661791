@keyframes do-levitation {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0.2em);
  }
}

.levitate {
  animation: do-levitation 0.8s alternate ease-in-out infinite;
}
